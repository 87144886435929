import React, { useRef } from 'react'
import {
  Sticky2Styled,
  StyledHeroContent,
  StyledHeroInner,
  StyledHeroOuter,
} from './style'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'
import { SpringFadeUp } from '@utils/animations'
import { Crossfade } from '@components/cross-fade/cross-fade'
import { Button } from '@components/button'

export const Hero: React.FC<any> = (props: any) => {
  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()
  const Sticky2 = () => (
    <Sticky2Styled>
      <Controller>
        <div>
          <Scene triggerHook="onLeave" duration={500} pin>
            {(progress: any) => (
              <div className="sticky">
                <Timeline totalProgress={progress}>
                  <div className="hero-skip">
                    <SpringFadeUp delay={500}>
                      <Tween to={{ opacity: 0 }} duration={500}>
                        <button
                          onClick={executeScroll}
                          className="c-button c-button_secondary c-button_small c-fullscreen-video__go-forward"
                        >
                          Scroll down
                        </button>
                      </Tween>
                    </SpringFadeUp>
                  </div>
                  <SpringFadeUp delay={500}>
                    <Tween to={{ width: '100vw' }} duration={500}>
                      <div className="animation">
                        <Crossfade
                          images={[
                            'img/Motiongraph_34_Hill_Top_Road_Chichester_New_York_Moving_Camera_Obscura_Maciej_Markowicz.jpg',
                            'img/Motiongraph_22_Met_at_5th_Avenue_Manhattan_New_York_Camera_Obscura_Maciej_Markowicz.jpg',
                            'img/Motiongraph_30_7th_Avenue_at_Perry_St_Manhattan_New_York_Camera_Obscura_Maciej_Markowicz.jpg',
                            'img/Motiongraph_08_Manhattan_New_York_Brooklyn_Bridge_Moving_Camera_Obscura_Maciej_Markowicz.jpg',
                          ]}
                          interval={8000}
                          width={'100%'}
                        />
                      </div>
                    </Tween>
                  </SpringFadeUp>
                </Timeline>
              </div>
            )}
          </Scene>
        </div>
      </Controller>
    </Sticky2Styled>
  )

  document.documentElement.style.setProperty('--header-text-color', '#0F0F0F')
  document.documentElement.style.setProperty(
    '--header-text-color-revert',
    '#ffffff',
  )
  document.documentElement.style.setProperty(
    '--header-text-color-revert-alpha',
    '#ffffff20',
  )

  //  const {}
  return (
    <StyledHeroOuter>
      <StyledHeroInner>
        <StyledHeroContent>
          <h1>Discover the artistry of Camera Obscura in motion.</h1>
          <p>
            Explore the distinctive artworks of Maciej Markowicz, which employ
            the ancient method of camera obscura to capture the essence of time
            and motion.
          </p>
          <Button
            size="large"
            sp="mr-1"
            linkTitle={'Explore'}
            linkTo={'works'}
          ></Button>
          <Button
            variant="outline"
            size="large"
            linkTitle={'Studio Visit'}
            linkTo={'camera-obscura-boat-tour-berlin'}
          ></Button>
        </StyledHeroContent>

        <Crossfade
          images={[
            'img/Motiongraph_34_Hill_Top_Road_Chichester_New_York_Moving_Camera_Obscura_Maciej_Markowicz.jpg',
            'img/Motiongraph_22_Met_at_5th_Avenue_Manhattan_New_York_Camera_Obscura_Maciej_Markowicz.jpg',
            'img/Motiongraph_30_7th_Avenue_at_Perry_St_Manhattan_New_York_Camera_Obscura_Maciej_Markowicz.jpg',
            'img/Motiongraph_08_Manhattan_New_York_Brooklyn_Bridge_Moving_Camera_Obscura_Maciej_Markowicz.jpg',
          ]}
          interval={3200}
          height={'600px'}
        />

        {/* <Sticky2 /> */}
        {/* <div ref={myRef}></div> */}

        {/* <StyledHeroOuter>
        <StyledHeroInner>
          <img
            height="800px"
            src={`img/hero1.jpg`}
            alt="light motion photo paper 8 sec exposure"
          />
        </StyledHeroInner>
      </StyledHeroOuter> */}
      </StyledHeroInner>
    </StyledHeroOuter>
  )
}

// export const query = graphql``
