import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: ${props => props.height};
  width: ${props => props.width};

  ${breakpoint.lt('md')`height: 300px; max-width: calc(90vw);`};
`

export const BackgroundBackground = styled.div`
  // position: absolute;
  height: 100%;
  width: 100%;
`

export const Background = styled.img`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: ${props => props.height};
  width: 100%;
  object-fit: contain;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${breakpoint.lt('md')`height: auto !important;`};

  &.active {
    opacity: 1;
    transition: opacity ${props => props.transitionDelay};
  }
  &.inactive {
    opacity: 0;
    transition: opacity ${props => props.transitionDelay};
  }
`
