import Link from 'gatsby-link'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/grid'
import Masonry from 'react-responsive-masonry'

import { rem } from '@styles/02-tools/tools.common'

import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { typography } from '@styles/text'
import { LargeContainerBox } from '@components/container/container'

export const StyledContainerBox = styled(LargeContainerBox)`
  background-color: ${typography.background};
`

export const ProjectImage = styled(GatsbyImage)`
  margin-bottom: 0.5rem;
`

export const StyledMasonry = styled(Masonry)`
  margin-bottom: 5rem;

  .c-masonry-grid__image {
    margin-bottom: 1.5rem;
    ${breakpoint.gt('md')`
  margin-bottom: 0;

`}
  }

  .c-masonry-grid__conent {
    ${breakpoint.gt('md')`
justify-content: space-around;

`}
  }

  .c-masonry-grid__item:nth-of-type(1n) {
    .c-masonry-grid__conent {
      ${ProjectImage} {
        max-width: 480px;
      }
    }
  }

  .c-masonry-grid__item:nth-of-type(2n) {
    .c-masonry-grid__conent {
      ${breakpoint.gt('md')`
flex-direction: row-reverse;
`}

      .c-masonry-grid__text {
      }
      ${ProjectImage} {
        max-width: 549px;
      }
    }
  }

  .c-masonry-grid__item:nth-of-type(3n) {
    .c-masonry-grid__conent {
    }
  }
`

export const ProjectSuperscription = styled.div`
  margin-bottom: 0.5rem;
`

export const SectionTitle = styled.div`
  margin: 0 auto ${rem(48)}rem;
  text-align: center;

  ${breakpoint.gt('md')`
  max-width: 50%;
  `}
`

export const ProjectTitle = styled.h5`
  font-size: ${rem(20)}rem;
  margin-bottom: 0.75rem;
`

export const ProjectSummary = styled(Box)`
  line-height: 1.25em;


  ${breakpoint.gt('md')`
          max-width: 35%;
            display: flex;
  justify-content: center;
  flex-direction: column;
`}

}
`

export const ProjectLinkInner = styled.div`
  // display: flex;
  // align-items: center;
`

export const ProjectLink = styled(Link)`
  color: ${typography.text};
  cursor: pointer;
  display: flex;
  flex-direction: column;

  ${ProjectLinkInner} {
    border-radius: 0;

    img {
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(0.99);
      }
    }
  }
`

export const StyledButton = styled.button`
  display: block;
  margin: 0 auto;
  margin-bottom: ${rem(80)}rem;
  max-width: ${rem(200)}rem;
`

export const DateBox = styled(Box)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`
