import React from 'react'
import styled from '@emotion/styled'

import {
  oWrapperOuter,
  oWrapperInner,
  oLargeWrapperInner,
} from '@styles/05-objects/objects.wrapper'
import { rem, typography } from '@styles/text'
import { breakpoint } from '@styles/02-tools/tools.breakpoints'
import { colors } from '@styles/colors'

export const StyledHeroOuter = styled.section`
  ${oWrapperOuter}
  margin-top: 104px;

  ${breakpoint.gt('md')`
  margin-top: 154px;
  padding-bottom: 5rem;
  border-bottom: 1px solid;
  `}
`

export const StyledHeroContent = styled.div`
  margin-bottom: 3rem;

  ${breakpoint.gt('md')`
  max-width: 50%;
  margin-bottom: 0;
  `}

  h1 {
    margin-bottom: 1.5rem;
  }

  p {
    font-size: ${typography.dimensions.fontSize.large}px;
    margin-bottom: 2rem;
  }
`

export const StyledHeroInner = styled.div`
  ${oWrapperInner}

  position: relative;
  margin-top: 104px;
  align-items: center;
  gap: 5rem;

  ${breakpoint.gt('md')`
  display: flex;
  margin-top: 154px;

  `}
`

export const StyledHeroText = styled.div``
export const Sticky2Styled = styled.section`
  .hero-skip {
    position: absolute;
    z-index: 100;
    top: 99.6vh;
    left: 50%;
    transform: translateX(-50%);
    height: 100px;

    .c-button {
      color: ${colors.light};
    }
  }

  @keyframes move-left-to-right {
    from {
      transform: translate(0, -50%);
    }
    to {
      transform: translate(-50%, -50%);
    }
  }
  overflow: hidden;

  .sticky {
    height: 70vh;
    width: 100%;

    ${breakpoint.gt('md')`
    height: 100vh;
    `}

    & .animation {
      width: 300px;
      height: 100%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;

      ${breakpoint.gt('md')`
      width: 720px;
    `}
    }
    .heading {
      position: absolute;
      height: 100vh;
      width: 100%;
      z-index: 99;
      h1 {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
        display: inline-flex;
        word-spacing: 0.5em;
        span {
          word-spacing: normal;
          display: inline-block;
        }
        white-space: nowrap;
        font-size: ${rem(119)}rem;

        margin: 0;
        z-index: 100;
        // animation: move-left-to-right linear 40s infinite;

        > * {
          position: absolute;
          min-width: 100vw;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
        }
      }

      .heading-animation-wrapper {
        display: inline-flex;
        div:not(:last-of-type) {
          margin-right: 1em;
        }
      }
    }
  }
`
