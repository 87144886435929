import React from 'react'
import { Flex, Box } from '@rebass/grid'
import { SmallContainerFlex } from '@components/container/container'

export const FAQs: React.FC<any> = (props: any) => {
  return (
    <SmallContainerFlex
      innerAttributes={{
        flexDirection: 'column',
      }}
      className="c-faq"
    >
      <Box textAlign="center" mb="5rem">
        <h2>FAQs</h2>
        <p>
          Learn more about the camera obscura process and the unique art created
          by Maciej Markowicz.
        </p>
      </Box>

      <Box mb="3rem">
        <h4>What is camera obscura?</h4>
        <p>
          Camera obscura is a 2000-year-old technique for projecting outside
          images onto a wall inside a darkened room. Maciej Markowicz uses this
          technique to create large-scale unique paper negatives.
        </p>
      </Box>

      <Box mb="3rem">
        <h4>How does it work?</h4>
        <p>
          The camera obscura works by allowing light to enter through a small
          hole or lens, projecting an image onto a surface inside the camera.
          Maciej Markowicz's camera obscura boat is a unique photographic device
          that captures stunning images of Berlin.
        </p>
      </Box>

      <Box mb="3rem">
        <h4>What makes it unique?</h4>
        <p>
          What sets Maciej Markowicz's art apart is the use of mobilized camera
          obscura to create life-size color photographic paper negatives. The
          process is uneditable, requiring a master photographer to capture the
          motion
        </p>
      </Box>

      <Box mb="3rem">
        <h4>How can I book?</h4>
        <p>
          To book a 3-hour boat ride for you and up to 10 friends or colleagues
          on the Spree in Berlin, contact us at info@maciejmarkowicz.com.
          Donations to Maciej's Artist Fund are appreciated to cover his time
          and running costs.
        </p>
      </Box>

      <Box mb="3rem">
        <h4>What will I see?</h4>
        <p>
          During the boat ride, Maciej will introduce you to his camera obscura
          process and show you his unique creations. If conditions allow, you
          may even witness the creation of a new piece of art in the dark room
          of the Camera Obscura boat.
        </p>
      </Box>
    </SmallContainerFlex>
  )
}
