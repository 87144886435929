import React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Hero } from '@components/hero/hero'
import { Layout } from '@components/layout'
import { ContainerBox, ContainerFlex } from '@components/container'
import { SpringFadeUp, TrailFadeUp } from '@utils/animations'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import {
  LargeContainerBox,
  SmallContainerFlex,
} from '@components/container/container'
import { Button } from '@components/button'
import { ArticleListNewSlice } from '@slices/article-list-new/article-list'
import { FAQs } from '@components/faq'

type Props = {
  data: any
  pageContext: any
}

const Index: React.FC<Props> = props => {
  const { meta, header, seo, main, footer } = props.data
  const works = props?.data?.works
  const news = props?.data?.news

  return (
    <Layout
      seo={seo}
      header={header}
      footer={footer}
      // theme={theme}
      activeMeta={meta}
    >
      <Hero />
      <ArticleListNewSlice
        showDate={false}
        posts={news.edges.map(post => post).slice(0, 3)}
        title="Latest news"
        loadMoreLabel="Explore More"
      ></ArticleListNewSlice>
      <SmallContainerFlex>
        <blockquote>
          <TrailFadeUp>
            <p>
              I am an artist, who is turning objects into mobile Camera
              Obscuras, making 8 seconds paintings with light and motion on
              color photographic paper
            </p>
            <br />
            <Button linkTo={'about'} linkTitle={'discover the artist'} />
          </TrailFadeUp>
        </blockquote>
      </SmallContainerFlex>
      <LargeContainerBox>
        <SpringFadeUp className="u-fullwidth">
          <div className="c-content-video">
            <iframe
              loading="lazy"
              className="c-content-video__iframe"
              src="https://player.vimeo.com/video/661944718?background=1&autoplay=1&muted=1&loop=1"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay"
            ></iframe>
          </div>
        </SpringFadeUp>
      </LargeContainerBox>
      <ArticleListNewSlice
        showDate={false}
        posts={works.edges.map(post => post).slice(0, 3)}
        title="Motiongraphs"
        description="Collection of one-of-a-kind art pieces created using moblie Camera Obscura"
        loadMoreLabel="Explore More"
        exploreMoreLink="/works"
      ></ArticleListNewSlice>
      <ContainerFlex className="homep-text2wrapper">
        <h1 className="homep-text2">
          <SpringFadeUp>i paint with</SpringFadeUp>
          <SpringFadeUp delay={400}>
            <span className="u-text-decoration-underlined">light</span>
          </SpringFadeUp>
          <SpringFadeUp delay={400}>and</SpringFadeUp>
          <SpringFadeUp delay={600}>
            <span className="u-text-decoration-underlined">motion</span>
          </SpringFadeUp>
          <br />
          <div className="second-line">
            <SpringFadeUp delay={700}>on color</SpringFadeUp>
            <SpringFadeUp delay={900}>
              <span className="last u-text-decoration-underlined">
                photographic
              </span>
              <span className="u-text-decoration-underlined">paper</span>
            </SpringFadeUp>
          </div>
        </h1>
      </ContainerFlex>{' '}
      <ContainerBox>
        <ResponsiveMasonry columnsCountBreakPoints={{ 0: 1, 992: 2 }}>
          <Masonry columnsCount={2} gutter={'5rem'}>
            <SpringFadeUp>
              <figure>
                <img
                  loading="lazy"
                  src="img/maciej_markowicz_inside_camera_obscura_boat_with_the_artwork_from_motiongraph_collection_berlin_2021.jpg"
                  alt="Maciej Markowicz inside Camera Obscura boat, with the artwork
                  from Motiongraph Collection. Berlin 2021"
                />
                <figcaption>
                  Maciej Markowicz inside Camera Obscura boat, with the artwork
                  from Motiongraph Collection. Berlin 2021
                </figcaption>
              </figure>
            </SpringFadeUp>
            <SpringFadeUp delay={200}>
              <figure>
                <img
                  loading="lazy"
                  src="img/Maciej_Markowicz_-Berlin_Artist_studio_Visit.jpg"
                  alt="Maciej_Markowicz_-Berlin_Artist_studio_Visit"
                />
                <figcaption>
                  Artist Maciej Markowicz with framed artwork titled:
                  Motiongraph #17 Center Boulevard, Long Island City, New York,
                  April 2016, in private collection in Berlin from 2020.
                </figcaption>
              </figure>
            </SpringFadeUp>
            <SpringFadeUp delay={300}>
              <figure>
                <img
                  loading="lazy"
                  src="img/maciej_with_motiongraph_108_at_french_museum_of_photography_s_collection_archive_november_2020.jpg"
                  alt="Maciej with Motiongraph #108 at French Museum of Photography's
                  Collection archive. November 2020"
                />
                <figcaption>
                  Maciej with Motiongraph #108 at French Museum of Photography's
                  Collection archive. November 2020
                </figcaption>
              </figure>
            </SpringFadeUp>
            <SpringFadeUp delay={400}>
              <figure>
                <img
                  loading="lazy"
                  src="img/opening_speech_for_exhibition_titled_motiongraphs_2015_2018_at_flo_peters_gallery_hamburg_june_2018.jpg"
                  alt="Opening Speech for Exhibition titled: Motiongraphs 2015-2018
                  at Flo Peters Gallery Hamburg, June 2018"
                />
                <figcaption>
                  Opening Speech for Exhibition titled: Motiongraphs 2015-2018
                  at Flo Peters Gallery Hamburg, June 2018
                </figcaption>
              </figure>
            </SpringFadeUp>
          </Masonry>
        </ResponsiveMasonry>
      </ContainerBox>
      <FAQs />
    </Layout>
  )
}

export default withPrismicPreview(Index)

export const pageQuery = graphql`
  query IndexQuery($locale: String) {
    meta: prismicHomepage(lang: { eq: $locale }) {
      lang
      type
      alternate_languages {
        uid
        lang
        document {
          ... on PrismicHomepage {
            id
            url
          }
        }
      }
    }
    seo: prismicHomepage(lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url(imgixParams: { width: 1200, height: 630 })
        }
      }
    }

    header: prismicMenu(lang: { eq: $locale }) {
      ...header
    }

    main: prismicHomepage(lang: { eq: $locale }) {
      data {
        page_title {
          text
        }
      }
    }

    works: allPrismicWorksPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          url
          data {
            date(formatString: "MMMM YYYY")
            feature_image {
              gatsbyImageData
              alt
              fluid {
                aspectRatio
              }
            }
            post_title {
              text
              richText
            }
            superscription {
              text
              richText
            }
          }
        }
      }
    }

    news: allPrismicNewsPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          uid
          url
          data {
            date(formatString: "MMMM YYYY")
            feature_image {
              gatsbyImageData
              alt
            }
            post_title {
              text
            }
            superscription {
              richText
            }
          }
        }
      }
    }

    footer: prismicFooter(lang: { eq: $locale }) {
      ...footer
    }
  }
`
